import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Loading from "components/Loading";
import { Typography, Row, Col, Card, Button, Form, Switch, Modal } from "antd";
import Parse from 'html-react-parser';
import PageTitle from "components/PageTitle";
import { generateFormElement } from "utils/common";
import OnboardingServiceForm from "components/Forms/OnboardingServiceForm";
// styles
import 'assets/styles/store.scss';
// request
import { getServiceDetail, updateService, getPreviewOnboardingDataOfService } from 'requests/service';
import { handleButtonUrl } from "requests/common";
import { generateServiceSdkButton, handleServiceSdkButton } from "utils/services";


const { Title } = Typography;

const ServiceDetail = () => {
    const [loading, setLoading] = useState(true);
    const [titles, setTitles] = useState([{ path: '/services', title: 'Services' }]);
    const [record, setRecord] = useState({});
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [aboveButtons, setAboveButtons] = useState([]);
    const [belowButtons, setBelowButtons] = useState([]);
    const [visibleOnboardingModal, setVisibleOnboardingModal] = useState(false);
    const [onboardingData, setOnboardingData] = useState({ data: [], onlyIndian: false });

    const params = useParams();
    const location = useLocation();

    const [configForm] = Form.useForm();

    const user = useSelector(state => state.auth.authUser);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (record && record.service && record.service.config && record.service.config.buttons) {
            const aboveButtons = [], belowButtons = [];
            let buttons = record.service.config.buttons;
            if (!record.service.can_onboarding) {
                buttons = buttons.filter(button => button.type != 'onboarding');
            }
            buttons.forEach(item => {
                if (item.position === 'below_form') belowButtons.push(item);
                else aboveButtons.push(item);
            });

            setAboveButtons(aboveButtons);
            setBelowButtons(belowButtons);
        }
    }, [record]);

    const getData = async () => {
        setLoading(true);
        const serviceDetail = await getServiceDetail(params.id);

        setRecord(serviceDetail);
        setTitles([
            { path: '/services', title: 'Services' },
            { path: location.pathname, title: serviceDetail.service.name }
        ]);
        setLoading(false);
    }

    const onClickServiceButton = async (url, type) => {
        try {
            if (type === 'oauth') {
                const response = await handleButtonUrl(url);
                if (response.redirect_url) window.open(response.redirect_url, '_blank');
            } else if (type === 'onboarding') {
                // get preview onboarding data
                const response = await getPreviewOnboardingDataOfService({ service_id: record.service?.id });
                if (response) {
                    setOnboardingData(response);
                    setVisibleOnboardingModal(true);
                }
                // confirm to start onboarding
            } else if (type === 'sdk') {
                await handleServiceSdkButton(record.service?.id, record.id);
            } else {
                window.open(url, '_blank');
            }
        } catch (err) {
            console.log(err);
        }
    }

    const onSubmit = async (data) => {
        try {
            setLoadingSubmit(true);

            const { status, ...restData } = data;
            await updateService(record.id, {
                status: status ? 1 : 0,
                config: {
                    ...record.config,
                    ...restData
                }
            });
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingSubmit(false);
        }
    }

    const onCloseOnboardingModal = async (shouldSaveConfig = false) => {
        await getData();
        setVisibleOnboardingModal(false);
        if (shouldSaveConfig) {
            configForm.submit();
        }
    }

    return (
        <div className="mb-36">
            <PageTitle titles={titles} />
            {
                loading ? (
                    <Loading />
                ) : (
                    <div>
                        {
                            record.service.config ? (
                                <div>
                                    {
                                        record.service.config.information ? (
                                            <div>{Parse(record.service.config.information)}</div>
                                        ) : null
                                    }
                                    {
                                        aboveButtons.map((item, index) => (
                                            <Card key={index} className="mb-16">
                                                <Row justify="space-between" align="middle" gutter={16}>
                                                    <Col span={18}>{item.description}</Col>
                                                    {
                                                        item.type === 'sdk' ? (
                                                            generateServiceSdkButton(
                                                                record.service.id,
                                                                {
                                                                    title: item.label,
                                                                    onClick: () => onClickServiceButton(item.url, item.type)
                                                                }
                                                            )
                                                        ) : (
                                                            <Button type={item.type !== 'default' ? 'primary' : 'default'} onClick={() => onClickServiceButton(item.url, item.type)}>{item.label}</Button>
                                                        )
                                                    }
                                                </Row>
                                            </Card>
                                        ))
                                    }
                                    {
                                        record.service.config.form_fields ? (
                                            <Card
                                                className="mb-16"
                                                title="Config service"
                                            >
                                                <Form
                                                    layout="vertical"
                                                    initialValues={{ status: record.status, ...record.config }}
                                                    form={configForm}
                                                    onFinish={onSubmit}
                                                >
                                                    <Form.Item name="status" label="Status" valuePropName="checked" rules={[{ required: true }]}>
                                                        <Switch />
                                                    </Form.Item>
                                                    {
                                                        record.service.config.form_fields.map((field, index) => (
                                                            <React.Fragment key={index}>
                                                                {generateFormElement(field)}
                                                            </React.Fragment>
                                                        ))
                                                    }

                                                    <Row justify="end">
                                                        <Button type="primary" htmlType="submit" size="large" loading={loadingSubmit}>Save</Button>
                                                    </Row>
                                                </Form>
                                            </Card>
                                        ) : null
                                    }
                                    {
                                        belowButtons.map((item, index) => (
                                            <Card key={index} className="mb-16">
                                                <Row justify="space-between" align="middle" gutter={16}>
                                                    <Col span={18}>{item.description}</Col>
                                                    {
                                                        item.type === 'sdk' ? (
                                                            generateServiceSdkButton(
                                                                record.service.id,
                                                                {
                                                                    title: item.label,
                                                                    onClick: () => onClickServiceButton(item.url, item.type)
                                                                }
                                                            )
                                                        ) : (
                                                            <Button type={item.type !== 'default' ? 'primary' : 'default'} onClick={() => onClickServiceButton(item.url, item.type)}>{item.label}</Button>
                                                        )
                                                    }
                                                </Row>
                                            </Card>
                                        ))
                                    }
                                </div>
                            ) : null
                        }
                    </div>
                )
            }
            <OnboardingServiceForm
                visible={visibleOnboardingModal}
                serviceId={record.service ? record.service.id : null}
                data={onboardingData.data}
                onlyIndian={onboardingData.onlyIndian}
                onClose={onCloseOnboardingModal}
            />
        </div>
    )
}

export default ServiceDetail;